<template>
    <div class="content-body">
        <div class="content" style="padding: 20px 40px" v-if="$i18n.locale == 'zh'">
            <!--试卷问题问题-->
            <el-card class="box-card" shadow="never" style="margin-bottom: 20px" >
                <p style="margin: 0" class="box-card-text" v-html="qusData.question_title_cn"></p>
            </el-card>
            <div class="sub-question">
                <!--题目-->
                <el-card class="box-card" style="margin-bottom: 20px" shadow="never" v-if="qusData.question_sub_cn!=''">
                    <div class="box-card-text" v-html="replaceImg(qusData.question_sub_cn)"></div>
                </el-card>

                <div >{{$t('home.CorrectAnswer')}}:</div>
                <el-card class="box-card" shadow="never" style="margin-bottom: 10px" >
                    <div class="form-check" v-for="(item,index) in qusData.question_option_cn" :key="index">
                        <input type="radio" disabled v-model="qusAnswer" :name="'subQuestionAnswer'+index" :id="'Answer'+index" :value="item.key" style="width: 20px; height: 20px;">
                        <label :for="'Answer'+index" class="form-check-label"  style="font-size: 16px; margin-left: 4px;">
                            <p v-html="item.values[0]"></p>
                        </label>
                    </div>
                </el-card>
                <el-card class="box-card"  shadow="never" >
                    <div>{{$t('home.Explain')}}:</div>
                    <div>{{qusData.question_explain_cn}}</div>
                </el-card>
            </div>
        </div>
        <div class="content" style="padding: 20px 40px" v-if="$i18n.locale== 'en'">
            <!--试卷问题问题-->
            <el-card class="box-card" shadow="never" style="margin-bottom: 20px" >
                <p style="margin: 0" class="box-card-text" v-html="qusData.question_title_en"></p>
            </el-card>
            <div class="sub-question">
                <!--题目-->
                <el-card class="box-card" style="margin-bottom: 20px" shadow="never" v-if="qusData.question_sub_en!=''">
                    <div class="box-card-text" v-html="replaceImg(qusData.question_sub_en)"></div>
                </el-card>
                <!--选择答案区域-->

                <div >{{$t('home.CorrectAnswer')}}:</div>
                <el-card class="box-card" shadow="never" style="margin-bottom: 10px" >
                    <div class="form-check" v-for="(item,index) in qusData.question_option_en" :key="index">
                        <input type="radio" disabled v-model="qusAnswer" :name="'subQuestionAnswer'+index" :id="'Answer'+index" :value="item.key" style="width: 20px; height: 20px;">
                        <label :for="'Answer'+index" class="form-check-label"  style="font-size: 16px; margin-left: 4px;">
                            <p v-html="item.values[0]"></p>
                        </label>
                    </div>
                </el-card>
                <el-card class="box-card" style="margin-bottom: 20px" shadow="never" >
                    <div>{{$t('home.Explain')}}:</div>
                    <div>{{qusData.question_explain_en}}</div>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['qusData'],
        name: "ChoiceQuestion",
        data(){
            return{
                checkedValue:'',
                qusAnswer:''
            }
        },
        created() {
            console.log(this.qusData)
            this.$nextTick(()=>{
                this.qusAnswer = this.qusData.question_answer_cn
                this.checkedValue = this.qusData.your_answer
                console.log('选项', this.checkedValue)
            })

        },
        methods:{
            answer(){
                // let that = this
                console.log(this.$parent.$parent)
                this.$http.post('question/save_answer',{
                    question_id:this.qusData.id,
                    practice_id:localStorage.getItem('practice_id')-0,
                    answer:this.checkedValue,
                    sign:0
                }).then(res =>{
                    console.log(res)
                    if (this.$store.state.active != this.$store.state.ListByExamId.length){
                        this.$store.state.active++
                        this.$parent.$parent.updateQuestion(this.$store.state.ListByExamId[this.$store.state.active])
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
.form-check{
    display: flex;
    align-items: center;
}
</style>