<template>
    <div class="content-body">
        <div class="content" style="padding: 20px 40px" v-if="$i18n.locale == 'zh'">
            <!--试卷问题问题-->
            <el-card class="box-card" shadow="never" style="margin-bottom: 20px" >
                <p style="margin: 0" class="box-card-text" v-html="qusData.question_title_cn"></p>
            </el-card>
            <div class="sub-question">
                <!--选择答案区域-->
                <div >{{$t('home.CorrectAnswer')}}:</div>
                <el-card class="box-card" style="margin-bottom: 20px" shadow="never" v-if="qusData.question_sub_cn!=''">
                    <div class="supp-p" id="answer_input" v-html="replaceImg(qusData.question_sub_cn)"></div>
                </el-card>
                <el-card class="box-card" style="margin-bottom: 20px" shadow="never" >
                    <div>{{$t('home.Explain')}}:</div>
                    <div>{{qusData.question_explain_cn}}</div>
                </el-card>
            </div>
        </div>
        <div class="content" style="padding: 20px 40px" v-if="$i18n.locale == 'en'">
            <!--试卷问题问题-->
            <el-card class="box-card" shadow="never" style="margin-bottom: 20px" >
                <p style="margin: 0" class="box-card-text" v-html="qusData.question_title_en"></p>
            </el-card>
            <div class="sub-question">
                <!--题目-->

                <!--选择答案区域-->
                <div >{{$t('home.CorrectAnswer')}}:</div>
                <el-card class="box-card" style="margin-bottom: 20px" shadow="never" v-if="qusData.question_sub_en!=''">
                    <div class="supp-p" id="answer_input" v-html="replaceImg(qusData.question_sub_en)"></div>
                </el-card>
                <el-card class="box-card" style="margin-bottom: 20px" shadow="never" >
                    <div>{{$t('home.Explain')}}:</div>
                    <div>{{qusData.question_explain_en}}</div>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:['qusData'],
        name: "supplementQuestion",
        data(){
            return{

            }
        },
        watch:{
            '$i18n.locale'(){
                // alert('111')
                this.$nextTick(()=>{
                    this.setAnswer()
                })
            },
            $route() {
                console.log('1111')
                this.setAnswer()
            },
            '$store.state.qusData'(){
                console.log('改变题目')
                this.$nextTick(()=>{
                    this.setAnswer()
                })

            }
        },
        created() {
            console.log(this.qusData)
            this.$nextTick(()=>{
                this.setAnswer()

                // if (this.qusData.your_answer){
                //     this.setMyAnswer()
                // }
            })
        },
        methods:{
            answer(){
                let answerNr = this.getTiankong()
                if (answerNr){
                    this.$http.post('question/save_answer',{
                        question_id:this.qusData.id,
                        practice_id:localStorage.getItem('practice_id')-0,
                        answer:answerNr.join(','),
                        sign:0
                    }).then(res =>{
                        console.log(res)
                        if (this.$store.state.active != this.$store.state.ListByExamId.length){
                            this.$store.state.active++
                            this.$parent.$parent.updateQuestion(this.$store.state.ListByExamId[this.$store.state.active])
                        }
                    })
                }

            },
            // 获取填空题答案
            getTiankong(){
                let that = this
                let answer = []
                this.$('.supp-p').find('input').each(function () {
                    if (that.$(this).val()!==''){
                        answer.push(that.$(this).val())
                    }
                })
                if (answer.length>0){
                    return answer
                }else {
                    return false
                }

            },
            // 给填空题赋值答案
            setAnswer(){
                this.$forceUpdate();
                console.log('执行了--------。')
                let answerList = JSON.parse(this.qusData.question_answer_cn)
                let that = this
                let index = 0
                that.$('#answer_input').find('input').each(function () {
                    that.$(this).val(answerList[index].answer)
                    that.$(this).prop('disabled', true)
                    index ++
                    console.log('填空答案',index,answerList)
                    console.log('填空答案之后',that.$(this).val())
                })

            },
            setMyAnswer(){
                let answerList = this.qusData.your_answer.split(',')
                let that = this
                let index = 0
                that.$('#answerA').find('input').each(function () {
                    that.$(this).val(answerList[index])
                    index ++
                    console.log('回答的',that.$(this).index())
                    console.log(index,answerList)
                })
                this.$forceUpdate();
            }
        }
    }
</script>

<style scoped lang="less">

</style>